"use client";

import { useState } from "react";
import { Button } from "@heroui/button";
import { Link } from "@heroui/link";
import { useMediaQuery } from "usehooks-ts";
import {
  Home,
  Map,
  FileText,
  Briefcase,
  Code,
  Settings,
  Mail,
  PenSquare,
  Atom,
  LayoutGrid,
} from "lucide-react";

export function SingularNavItem({ isActive, tab, idx }) {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [isHovered, setIsHovered] = useState(false);

  // Map icon names to components
  const iconMap = {
    Home: Home,
    MapRoute: Map,
    Article: FileText,
    Briefcase: Briefcase,
    Code: Code,
    Tools: Settings,
    Mail: Mail,
    Writing: PenSquare,
    Atom2: Atom,
    Cards: LayoutGrid,
  };

  // Get the icon component from our map
  const IconComponent = iconMap[tab.metadata.icon];

  return (
    <Button
      as={Link}
      key={idx}
      href={tab.metadata.slug}
      fullWidth
      variant="ghost"
      disableAnimation
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`group relative z-10 border-none shadow-none ${
        !isMobile && "justify-start"
      } transition-all duration-300 focus:outline-none focus:ring-0`}
      startContent={
        IconComponent ? (
          <div className="relative">
            <IconComponent
              className={`size-4 transition-all duration-300 ${
                isHovered || isActive ? "text-primary" : ""
              }`}
            />
            {(isHovered || isActive) && (
              <span className="absolute -bottom-1 left-1/2 h-0.5 w-1 -translate-x-1/2 rounded-full bg-primary transition-all duration-300" />
            )}
          </div>
        ) : null
      }
    >
      <span
        className={`transition-all duration-300 ${isHovered || isActive ? "translate-x-0.5" : ""}`}
      >
        {tab.metadata.label}
      </span>
    </Button>
  );
}
