"use client";

import { useEffect, useState } from "react";
import { ReactNode } from "react";
import {
  Home,
  Map,
  FileText,
  Briefcase,
  Atom,
  LayoutGrid,
  Edit,
  Sparkles,
  Settings,
  ExternalLink,
} from "lucide-react";
import { fetchAllPosts } from "@/lib/actions/blog";
import { fetchFeatures, fetchWorks } from "@/lib/actions/project";
import { fetchApps, fetchUtilities } from "@/lib/actions/playground";

// Types
type CommandGroup = {
  heading: string;
  items: CommandItem[];
};

type CommandItem = {
  id: string;
  label: string;
  url?: string;
  icon?: ReactNode;
  action?: () => void;
  isExternal?: boolean;
};

export function useCommandItems() {
  const [isLoading, setIsLoading] = useState(true);
  const [groups, setGroups] = useState<CommandGroup[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch all data
        const [posts, features, works, apps, utilities] = await Promise.all([
          fetchAllPosts(),
          fetchFeatures(100),
          fetchWorks(),
          fetchApps(),
          fetchUtilities(),
        ]);

        // Debug log for features and works
        console.log("Features data:", features);
        console.log("Works data:", works);

        // Navigation items group
        const navigationItems: CommandItem[] = [
          {
            id: "home",
            label: "Home",
            url: "/",
            icon: <Home className="size-4" />,
          },
          {
            id: "about",
            label: "About",
            url: "/about",
            icon: <Map className="size-4" />,
          },
          {
            id: "thoughts",
            label: "Thoughts",
            url: "/thoughts",
            icon: <Edit className="size-4" />,
          },
          {
            id: "playground",
            label: "Playground",
            url: "/work/playground",
            icon: <Atom className="size-4" />,
          },
          {
            id: "projects",
            label: "Projects",
            url: "/work/projects",
            icon: <LayoutGrid className="size-4" />,
          },
          {
            id: "features",
            label: "Features",
            url: "/features",
            icon: <Sparkles className="size-4" />,
          },
        ];

        // Posts/Writings group
        const postItems: CommandItem[] = posts.map((post) => ({
          id: `post-${post.id}`,
          label: post.title,
          url: `/thoughts/${post.slug}`,
          icon: <FileText className="size-4" />,
        }));

        // Features group - use external URLs instead of slug pages
        const featureItems: CommandItem[] = features.map((feature) => ({
          id: `feature-${feature.id}`,
          label: feature.title,
          url: feature.metadata.url,
          icon: <ExternalLink className="size-4" />,
          isExternal: true,
        }));

        // Works group
        const workItems: CommandItem[] = works.map((work) => ({
          id: `work-${work.id}`,
          label: work.title,
          url: `/work/${work.slug}`,
          icon: <Briefcase className="size-4" />,
        }));

        // Apps group
        const appItems: CommandItem[] = apps.map((app) => {
          const isExternalUrl = app.metadata?.url?.startsWith("http");
          return {
            id: `app-${app.id}`,
            label: app.title,
            url: app.metadata?.url,
            icon: <Atom className="size-4" />,
            isExternal: isExternalUrl,
          };
        });

        // Utilities group
        const utilityItems: CommandItem[] = utilities.map((utility) => ({
          id: `utility-${utility.id}`,
          label: utility.title,
          url: `/work/utilities/${utility.slug}`,
          icon: <Settings className="size-4" />,
        }));

        // Define groups - ensuring clear separation
        const commandGroups = [
          { heading: "Navigation", items: navigationItems },
          { heading: "Thoughts", items: postItems },
          { heading: "Features", items: featureItems },
          { heading: "Projects", items: workItems },
          { heading: "Playground", items: appItems },
          { heading: "Utilities", items: utilityItems },
        ];

        // Debug the final groups
        console.log("Command groups:", commandGroups);

        // Set the groups
        setGroups(commandGroups);
      } catch (error) {
        console.error("Error fetching command menu data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return { groups, isLoading };
}
