"use client";

import { Search } from "lucide-react";
import { Button } from "@heroui/button";
import { useEffect, useState } from "react";

type SearchButtonProps = {
  onClick: () => void;
};

export function SearchButton({ onClick }: SearchButtonProps) {
  const [shortcut, setShortcut] = useState<string>("");

  useEffect(() => {
    // Detect the platform and set the correct keyboard shortcut
    const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent);
    setShortcut(isMac ? "⌘K" : "⌃K");
  }, []);

  return (
    <Button
      variant="ghost"
      className="relative z-10 justify-start border-none shadow-none focus:outline-none focus:ring-0"
      onPress={onClick}
      startContent={<Search className="size-4" />}
    >
      <span className="sm:hidden">Search</span>
      <kbd className="ml-2 hidden rounded border border-foreground-200 bg-foreground-100 px-1.5 py-0.5 text-[10px] opacity-80 sm:ml-0 sm:inline-block">
        {shortcut}
      </kbd>
    </Button>
  );
}
