import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { SingularNavItem } from "./singular-nav-item";
import { Logo } from "./logo";
import { ContactMe } from "@/components/blocks/home/contact-me";
import styles from "@/styles/nav-animation.module.css";
import { SearchButton } from "@/components/command-menu/search-button";
import { useCommandMenu } from "@/components/command-menu/command-menu-provider";

const navigation = [
  {
    metadata: {
      icon: "Home",
      label: "Home",
      slug: "/",
    },
  },
  {
    metadata: {
      icon: "MapRoute",
      label: "About",
      slug: "/about",
    },
  },
  {
    metadata: {
      icon: "Writing",
      label: "Thoughts",
      slug: "/thoughts",
    },
  },
  {
    metadata: {
      icon: "Atom2",
      label: "Playground",
      slug: "/work/playground",
    },
  },
  {
    metadata: {
      icon: "Cards",
      label: "Projects",
      slug: "/work/projects",
    },
  },
];

interface NavListProps {
  pathname: string;
}

export function NavList({ pathname }: NavListProps) {
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const navContainerRef = useRef<HTMLDivElement | null>(null);
  const itemRefs = useRef<Map<string, HTMLDivElement>>(new Map());
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });
  const { setIsOpen } = useCommandMenu();

  // Set initial active tab and update when pathname changes
  useEffect(() => {
    const currentTab =
      navigation.find((tab) => {
        const splitTab = tab.metadata.slug?.substring(1);
        return pathname === tab.metadata.slug ||
          (splitTab && pathname.startsWith("/" + splitTab))
          ? tab.metadata.slug
          : null;
      })?.metadata.slug || "/";

    setActiveTab(currentTab);
  }, [pathname]);

  // Update dimensions when activeTab changes
  useLayoutEffect(() => {
    if (
      !activeTab ||
      !itemRefs.current.has(activeTab) ||
      !navContainerRef.current
    )
      return;

    const currentItem = itemRefs.current.get(activeTab);
    const containerRect = navContainerRef.current.getBoundingClientRect();
    const itemRect = currentItem?.getBoundingClientRect();

    if (itemRect) {
      setDimensions({
        width: itemRect.width,
        height: itemRect.height,
        // Adjust with a slight offset to perfectly center the background
        x: itemRect.left - containerRect.left,
        y: itemRect.top - containerRect.top,
      });
    }
  }, [activeTab]);

  // Also update dimensions on window resize
  useEffect(() => {
    const handleResize = () => {
      if (
        !activeTab ||
        !itemRefs.current.has(activeTab) ||
        !navContainerRef.current
      )
        return;

      const currentItem = itemRefs.current.get(activeTab);
      const containerRect = navContainerRef.current.getBoundingClientRect();
      const itemRect = currentItem?.getBoundingClientRect();

      if (itemRect && containerRect) {
        // Calculate the position relative to the container directly
        const offsetX = itemRect.left - containerRect.left;

        setDimensions({
          width: itemRect.width,
          height: itemRect.height,
          x: offsetX,
          y: itemRect.top - containerRect.top,
        });
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [activeTab]);

  // Clean up refs when component unmounts
  useEffect(() => {
    const refs = itemRefs.current;
    return () => {
      refs.clear();
    };
  }, []);

  const handleItemClick = (slug: string) => {
    setActiveTab(slug);
  };

  return (
    <ul className="relative m-0 flex h-max w-full select-none list-none items-center px-3 sm:px-1">
      <div
        className={`flex items-center ${styles.navContainer}`}
        ref={navContainerRef}
      >
        <div className="hidden lg:block">
          <Logo />
        </div>

        {/* Search Button */}
        <div className={`flex w-max sm:w-full ${styles.navItem} mx-1`}>
          <SearchButton onClick={() => setIsOpen(true)} />
        </div>

        {dimensions.width > 0 && (
          <div
            className={`absolute rounded-[--heroui-radius-medium] bg-foreground-200 ${styles.navBackground}`}
            style={{
              width: `${dimensions.width}px`,
              height: `${dimensions.height}px`,
              transform: `translateX(${dimensions.x}px)`,
            }}
          />
        )}

        {navigation.map((tab) => {
          const splitTab = tab.metadata.slug?.substring(1);
          const isActive =
            pathname === tab.metadata.slug ||
            (splitTab && pathname.startsWith("/" + splitTab));

          return (
            <div
              key={tab.metadata.slug}
              className={`flex w-max sm:w-full ${styles.navItem} mx-1`}
              ref={(el) => {
                if (el) itemRefs.current.set(tab.metadata.slug || "", el);
              }}
              onClick={() => handleItemClick(tab.metadata.slug || "/")}
            >
              <SingularNavItem
                idx={tab.metadata.slug}
                isActive={isActive}
                tab={tab}
              />
            </div>
          );
        })}
      </div>
      <div className="w-max shrink-0 pr-4 sm:pr-0">
        <ContactMe />
      </div>
    </ul>
  );
}
