"use client";

import { useState, createContext, useContext, ReactNode } from "react";
import { CommandMenu } from "./command-menu";
import { useCommandItems } from "@/lib/hooks/useCommandItems";

type CommandMenuContextType = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const CommandMenuContext = createContext<CommandMenuContextType | undefined>(
  undefined,
);

export function useCommandMenu() {
  const context = useContext(CommandMenuContext);
  if (!context) {
    throw new Error("useCommandMenu must be used within a CommandMenuProvider");
  }
  return context;
}

type CommandMenuProviderProps = {
  children: ReactNode;
};

export function CommandMenuProvider({ children }: CommandMenuProviderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { groups, isLoading } = useCommandItems();

  return (
    <CommandMenuContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
      <CommandMenu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        groups={groups}
        isLoading={isLoading}
      />
    </CommandMenuContext.Provider>
  );
}
