"use client";

import React from "react";
import { usePathname } from "next/navigation";
import { NavList } from "./nav-list";
import { ScrollShadow } from "@heroui/scroll-shadow";

export function Nav() {
  const pathname = usePathname();

  return (
    <>
      <nav className="fixed bottom-0 z-20 flex h-16 w-full select-none items-center justify-center overflow-x-auto border-t border-foreground-200 bg-default-50/75 shadow-none backdrop-blur-md lg:hidden">
        <ScrollShadow
          hideScrollBar
          orientation="horizontal"
          className="flex h-full w-full items-center"
        >
          <div className="m-auto w-full justify-center">
            <div className="m-auto flex w-full list-none items-center space-x-2">
              <NavList pathname={pathname!} />
            </div>
          </div>
        </ScrollShadow>
      </nav>
      {/* Desktop */}
      <div className="fixed top-4 z-50 flex w-full">
        <nav className="mx-auto hidden max-w-max transform items-center justify-center rounded-2xl border border-foreground-200 bg-default-50/75 py-1 shadow-none backdrop-blur-md duration-1000 ease-in-out lg:flex">
          <NavList pathname={pathname!} />
        </nav>
      </div>
    </>
  );
}
