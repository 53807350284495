"use client";

import { useState, useEffect, ReactNode } from "react";
import { Command } from "cmdk";
import { useRouter } from "next/navigation";
import { Search } from "lucide-react";

type Group = {
  heading: string;
  items: {
    id: string;
    label: string;
    url?: string;
    icon?: ReactNode;
    action?: () => void;
    isExternal?: boolean;
  }[];
};

type CommandMenuProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  groups: Group[];
  isLoading?: boolean;
};

export function CommandMenu({
  isOpen,
  setIsOpen,
  groups,
  isLoading,
}: CommandMenuProps) {
  const router = useRouter();
  const [search, setSearch] = useState("");

  // Debug output - log groups when they change
  useEffect(() => {
    console.log("Command menu groups:", groups);
  }, [groups]);

  // Keyboard shortcut handler
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setIsOpen(!isOpen);
      }

      if (e.key === "Escape") {
        setIsOpen(false);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [isOpen, setIsOpen]);

  // Handle item selection (navigation or action)
  const handleItemSelect = (item: {
    id: string;
    label: string;
    url?: string;
    action?: () => void;
    isExternal?: boolean;
  }) => {
    if (item.action) {
      item.action();
    } else if (item.url) {
      if (item.isExternal) {
        // Open external links in a new tab
        window.open(item.url, "_blank");
      } else {
        // Navigate to internal links using the router
        router.push(item.url);
      }
    }
    setIsOpen(false);
    setSearch("");
  };

  return (
    <Command.Dialog
      open={isOpen}
      onOpenChange={setIsOpen}
      label="Command Menu"
      className="fixed left-1/2 top-[20%] z-[1000] w-[95%] max-w-[640px] -translate-x-1/2 overflow-hidden rounded-2xl border border-foreground-200 bg-background/40 shadow-2xl backdrop-blur-3xl"
    >
      <div className="flex items-center gap-2 border-b border-foreground-200 p-3">
        <Search className="size-4 text-foreground-500 opacity-70" />
        <Command.Input
          value={search}
          onValueChange={setSearch}
          placeholder="Search content, pages, and more..."
          autoFocus
          className="w-full bg-transparent outline-none"
        />
      </div>
      <Command.List className="max-h-[400px] overflow-y-auto py-2">
        {isLoading ? (
          <div className="px-4 py-3 text-center text-sm text-foreground-500">
            Loading...
          </div>
        ) : (
          <>
            <Command.Empty className="px-4 py-3 text-center text-sm text-foreground-500">
              No results found.
            </Command.Empty>

            {groups.map(
              (group) =>
                // Only render groups with items
                group.items.length > 0 && (
                  <Command.Group
                    key={group.heading}
                    heading={group.heading}
                    className="[&_[cmdk-group-heading]]:bg-background-200 mb-2 [&_[cmdk-group-heading]]:px-4 [&_[cmdk-group-heading]]:py-2 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-bold [&_[cmdk-group-heading]]:uppercase [&_[cmdk-group-heading]]:tracking-wider [&_[cmdk-group-heading]]:text-foreground-500"
                  >
                    {group.items.map((item) => (
                      <Command.Item
                        key={item.id}
                        value={item.label}
                        onSelect={() => handleItemSelect(item)}
                        className={`flex cursor-pointer items-center border-l-2 border-transparent px-4 py-2 text-sm text-foreground data-[selected=true]:border-primary data-[selected=true]:bg-foreground-100`}
                      >
                        {item.icon && (
                          <span className="mr-2 flex items-center text-foreground-500">
                            {item.icon}
                          </span>
                        )}
                        {item.label}
                      </Command.Item>
                    ))}
                  </Command.Group>
                ),
            )}
          </>
        )}
      </Command.List>
    </Command.Dialog>
  );
}
