// app/providers.tsx
"use client";

import { HeroUIProvider, ToastProvider } from "@heroui/react";
import { useRouter } from "next/navigation";
import { CommandMenuProvider } from "@/components/command-menu/command-menu-provider";

export function Providers({ children }: { children: React.ReactNode }) {
  const router = useRouter();

  return (
    <HeroUIProvider navigate={router.push}>
      <ToastProvider />
      <CommandMenuProvider>{children}</CommandMenuProvider>
    </HeroUIProvider>
  );
}
