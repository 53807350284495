"use client";

import React from "react";
import Link from "next/link";

interface LogoProps {
  className?: string;
}

export function Logo({ className = "" }: LogoProps) {
  return (
    <Link href="/" className={`size-24 text-foreground ${className}`}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.60102 9.66394C11.0148 9.66394 12.161 8.51781 12.161 7.10394C12.161 5.6901 11.0148 4.54395 9.60102 4.54395C8.18714 4.54395 7.04102 5.6901 7.04102 7.10394C7.04102 8.51781 8.18714 9.66394 9.60102 9.66394Z"
          fill="currentColor"
        />
        <path
          d="M9.60102 27.4559C11.0148 27.4559 12.161 26.3098 12.161 24.8959C12.161 23.4821 11.0148 22.3359 9.60102 22.3359C8.18714 22.3359 7.04102 23.4821 7.04102 24.8959C7.04102 26.3098 8.18714 27.4559 9.60102 27.4559Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.3672 5.45662C24.7871 5.87669 25.023 6.44631 25.023 7.04031C25.023 7.63431 24.7871 8.20393 24.3672 8.62399L16.9909 16.0003L24.3672 23.3766C24.7752 23.7991 25.001 24.3649 24.9959 24.9522C24.9908 25.5396 24.7552 26.1014 24.3399 26.5167C23.9245 26.932 23.3628 27.1676 22.7754 27.1727C22.1881 27.1777 21.6223 26.9521 21.1999 26.544L12.2399 17.584C11.8199 17.1639 11.584 16.5943 11.584 16.0003C11.584 15.4063 11.8199 14.8367 12.2399 14.4166L21.1999 5.45662C21.6199 5.03669 22.1895 4.80078 22.7835 4.80078C23.3775 4.80078 23.9472 5.03669 24.3672 5.45662Z"
          fill="currentColor"
        />
      </svg>
    </Link>
  );
}
